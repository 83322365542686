import React from 'react';
import './Cart.css';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, query, where, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { User } from 'firebase/auth';

interface CartProps {
  isOpen: boolean;
  onClose: () => void;
}

const Cart: React.FC<CartProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = React.useState<any[]>([]);
  const [user, setUser] = React.useState<User | null>(null);

  React.useEffect(() => {
    const fetchCartItems = async () => {
      if (user) {
        const userId = user.uid;
        const cartRef = collection(db, 'cart');
        const q = query(cartRef, where('user_id', '==', `/users/${userId}`));

        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const cartData = querySnapshot.docs[0].data();
            setCartItems(cartData.skincare_product || []);
          } else {
            setCartItems([]);
          }
        } catch (error) {
          console.error("Error fetching cart items: ", error);
        }
      }
    };

    fetchCartItems();
  }, [user]);

  const handleShopNowClick = () => {
    onClose();
    navigate('/skincare');
  };

  return (
    <>
      <div className={`cart-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}></div>
      <div className={`cart ${isOpen ? 'open' : ''}`}>
        <div className="cart-header">
          <h2>Cart</h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <hr className="divider" />
        <div className="cart-body">
          {cartItems.length === 0 ? (
            <div className="empty-cart">
              <p className='empty-text'>Your cart is empty!</p>
              <p>Add skincare products to your cart</p>
              <button className="shop-now-btn" onClick={handleShopNowClick}>
                Shop Now
              </button>
            </div>
          ) : (
            <div className="cart-items">
              {/* Render cart items here */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
