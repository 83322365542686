import React, { useState } from 'react';
import './ForgotPassword.css';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const handleResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Check your inbox.');
        } catch (error) {
            setError(message);
        }
    };

    return (
        <div className="forgot-container">
            <h1 className='forgotLabel'>Reset Password</h1>
            <form className="forgot-form" onSubmit={handleResetPassword}>
                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
                
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required value={email} onChange={(e) => setEmail(e.target.value)} />

                <button type="submit" className="submit-button">Submit</button>

                <div className="back-login">
                    <a href="/login">Back to Login</a>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
