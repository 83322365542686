import React, { useEffect, useState } from 'react';
import './Membership.css';
import cover from '../assets/shop_skincare_cover.png';


import Image from '../assets/image1.jpg';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const Membership: React.FC = () => {
  const [coverImage, setCoverImage] = useState<string | null>(null);
    const [coverDescription, setCoverDescription] = useState<string | null>(null);

    useEffect(() => {
        const fetchCoverData = async () => {
            try {
                const coverCollection = collection(db, 'cover');
                const coverQuery = query(coverCollection, where('page_name', '==', 'membership'));
                const coverSnapshot = await getDocs(coverQuery);

                coverSnapshot.forEach(doc => {
                    const data = doc.data();
                    setCoverImage(data.cover);
                    setCoverDescription(data.description);
                });
            } catch (error) {
                console.error("Error fetching cover data: ", error);
            }
        };

        fetchCoverData();
    }, []);
  return (
    <div>
      {coverImage ? (
                <img src={coverImage} alt="Cover" className="cover-image" />
            ) : (
                <img src="" alt="" className="cover-image" />
            )}
            <div className="paragraph-box">
                <p>
                    {coverDescription || ''}
                </p>
            </div>
    <div className="membership-container">
      <div className="membership-column">
        <img src={Image} alt="Membership 1" className="membership-image" />
        <h2 className="membership-title">QD Glow
        <br></br>
        <span className='vip-price'>$199/month</span>
        </h2>
        <p className="membership-details">As a QD Glow member, you are able to apply the monthly amount you banked in your account toward any treatment and receive: 
        <br></br><br></br>
        <span className='percentage'>-10%</span> savings from the regular price services
        <br></br>
        <span className='percentage'>-10%</span> savings on all retail purchases
        <br></br><br></br>
        <span className='benefits'>Program Benefits:</span>
        <br></br>
        - Complimentary Skin Analysis
        <br></br>
        - Complimentary cosmetic consultations
        <br></br>
        - Exclusive Member only offers throughout the year
        <br></br>
        - Members are chosen for complimentary treatments/services during events
        <br></br>
        - Access to VIP only Events</p>
        <button className="membership-button" disabled>Join Now</button>
      </div>


      <div className="membership-column">
        <img src={Image} alt="Membership 2" className="membership-image" />
        <h2 className="membership-title">QD Renew <span className='popular'>(Most Popular!)</span>
        <br></br>
        <span className='vip-price'>$350/month</span></h2>
        <p className="membership-details">As a QD Renew member, you are able to apply the monthly amount you banked in your account toward any treatment and receive: 
        <br></br><br></br>
        <span className='percentage'>-15%</span> savings from the regular price services
        <br></br>
        <span className='percentage'>-15%</span> savings on all retail purchases
        <br></br><br></br>
        <span className='benefits'>Program Benefits:</span>
        <br></br>
        - Complimentary Skin Analysis
        <br></br>
        - Complimentary cosmetic consultations
        <br></br>
        - Exclusive Member only offers throughout the year
        <br></br>
        - Members are chosen for complimentary treatments/services during events
        <br></br>
        - Access to VIP only Events</p>
        <button className="membership-button" disabled>Join Now</button>
      </div>


      <div className="membership-column">
        <img src={Image} alt="Membership 3" className="membership-image" />
        <h2 className="membership-title">QD Radiance
        <br></br>
        <span className='vip-price'>$499/month</span>
        </h2>
        <p className="membership-details">As a QD Radiance member, you are able to apply the monthly amount you banked in your account toward any treatment and receive: 
        <br></br><br></br>
        <span className='percentage'>-20%</span> savings from the regular price services
        <br></br>
        <span className='percentage'>-20%</span> savings on all retail purchases
        <br></br><br></br>
        <span className='benefits'>Program Benefits:</span>
        <br></br>
        - Complimentary Skin Analysis
        <br></br>
        - Complimentary cosmetic consultations
        <br></br>
        - Exclusive Member only offers throughout the year
        <br></br>
        - Members are chosen for complimentary treatments/services during events
        <br></br>
        - Access to VIP only Events</p>
        <button className="membership-button" disabled>Join Now</button>
      </div>
    </div>
    </div>
  );
};

export default Membership;
