// src/App.tsx
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Membership from './pages/Membership';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Register from './pages/Register';
import './App.css';
import './assets/styles/fonts.css';
import './firebaseConfig';
import SearchResultsPage from './pages/SearchResultsPage';

// Lazy load your page components
const Home = lazy(() => import('./pages/Home'));
const Skincare = lazy(() => import('./pages/Skincare'));
const Gallery = lazy(() => import('./pages/Gallery'));
const Products = lazy(() => import('./pages/Products'));
const Profile = lazy(() => import('./pages/Profile'));
const Medspa = lazy(() => import('./pages/Medspa'));
const Services = lazy(() => import('./pages/Services'));
const Service = lazy(() => import('./pages/Service'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Events = lazy(() => import('./pages/Events'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogContent = lazy(() => import('./pages/BlogContent'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Reviews = lazy(() => import('./pages/Reviews'));
const SearchResults = lazy(() => import('./pages/SearchResultsPage'));
const BookApointment = lazy(() => import('./pages/Booking'))
const TermsOfService = lazy(() => import('./pages/TermsOfService'))
const PrivacyPoiicy = lazy(() => import('./pages/PrivacyPolicy'))


function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/skincare" element={<Skincare />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/products/:id" element={<Products />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/medspa" element={<Medspa />} />
            <Route path="/services/:id" element={<Services />} />
            <Route path="/service/:id" element={<Service />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/events" element={<Events />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-content" element={<BlogContent />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/search-results" element={<SearchResultsPage />} />
            <Route path="/book-an-appointment" element={<BookApointment />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPoiicy />} />
            {/* Add more routes as needed */}
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
