import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, Firestore, doc, DocumentSnapshot, getDoc  } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import Firebase Auth


const firebaseConfig = {
  apiKey: "AIzaSyAdx8XzalgOHP4sHQNxYSBE3vSrz2Bik10",
  authDomain: "qdskinnovations-6f04d.firebaseapp.com",
  projectId: "qdskinnovations-6f04d",
  storageBucket: "qdskinnovations-6f04d.appspot.com",
  messagingSenderId: "623529757294",
  appId: "1:623529757294:web:b0f2705f9abd827141347f",
  measurementId: "G-T9ZBP1PM8C"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Auth

export { db, auth };
